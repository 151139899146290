@import '../../../../bloko/common/styles/colors';
@import '../../../../bloko/common/styles/media/_screen';

.wrapper {
    border-top: 1px solid @color-gray-30;
}

.wrapper_redesigned {
    border-top: 0;

    @media @screen-gt-xs {
        border-top: 1px solid @color-gray-20;
    }
}
