@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/rounded-corner-base';
@import '~bloko/common/styles/colors';

@gradient-bg-layer: fade(@color-special-absolute-black, 60%) 0%;
@gradient-final-bg-layer: fade(@color-special-absolute-black, 0%) 100%;

@gradient-bg-layer-hover: fade(@color-special-absolute-black, 60%) 20%;

.root {
    background-size: cover;
    background-position: center;

    position: relative;
    cursor: pointer;
    padding: 24px;
    display: flex;
    align-items: flex-end;
    height: 180px;
    border-radius: @rounded-corner-default;
    box-sizing: border-box;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to top, @gradient-bg-layer, @gradient-final-bg-layer);

        &:hover {
            background-image: linear-gradient(to top, @gradient-bg-layer-hover, @gradient-final-bg-layer);
        }
    }

    @media @screen-gt-xs {
        height: 228px;
    }
}

.inner {
    width: 100%;
    isolation: isolate;
}

.timeline {
    display: flex;
    align-items: center;
    gap: 8px;
}

.timeline-item {
    color: @color-white;
    flex-shrink: 0;
}

.play {
    background: url('./play.svg');
    background-size: cover;

    width: 16px;
    height: 16px;

    flex-shrink: 0;
    margin-right: 8px;
}

.vacancy-title {
    color: @color-white;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 70px;
    overflow: hidden;
}

.progress {
    width: 100%;
    height: 7px;
}

.progress-item {
    fill: @color-white;
}
