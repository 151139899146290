// tempexp_30059_file
@import '~bloko/common/styles/media/_screen';

.not-verifieg-container {
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: baseline;

    @media @screen-gt-xs {
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }
}
