@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/spacing-base';

@import '../../../../style-mixins/shadow-block';

.root {
    .shadow-block();

    padding: @shadow-block-padding;
    @media @screen-gt-xs {
        padding: @shadow-block-desktop-padding;
    }
    @media print {
        .shadow-block-clear();

        padding: 0;
    }
}

.video-player {
    width: 100%;
    height: 100%;
    max-width: 1120px;
    display: flex;
}

.close {
    display: none;

    @media @screen-gt-xs {
        display: inline;
    }
}

.sticky-container {
    position: sticky;
    top: 0;
}

.modal-bottom {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: @spacing-base * 2;

    @media @screen-gt-xs {
        flex-direction: row;
        gap: 0;
    }
}

.spacer {
    @media @screen-gt-xs {
        flex-shrink: 0;
        flex-grow: 1;
        width: @spacing-base * 2;
    }
}

.report-video {
    text-align: left;

    @media @screen-gt-xs {
        flex-direction: row;
    }
}
