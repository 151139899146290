@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.wrapper {
    @media @screen-gt-xs {
        max-width: 612px;
    }
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media @screen-gt-xs {
        flex-direction: row;
    }
}

.input-wrapper {
    flex: 1;
}

.button-wrapper {
    flex: 0;
}
