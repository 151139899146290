@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/rounded-corner-base';
@import '~bloko/common/styles/_column-variables';

.wrapper {
    padding: 28px;
    background: @color-gray-10;
    border-radius: @rounded-corner-default;
}

.hybrid-constructor-wrapper {
    display: flex;
    justify-content: center;
}
