@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.vacancy-response-questions_type-response.vacancy-response-questions_bottom-gap {
    margin-bottom: 44px;
}

.vacancy-response-questions_type-question.vacancy-response-questions_bottom-gap {
    margin-bottom: 48px;
}

.vacancy-response-questions_type-question {
    padding: 24px;
    border-radius: 4px;

    background-color: @color-gray-10;
}

.vacancy-response-suggest {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
}

.input {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.vacancy-response-questions_with-suggest .input-wrapper {
    @media @screen-lt-s {
        flex-direction: column;
        gap: 12px;
    }
}

.input-wrapper {
    display: flex;
    gap: 8px;

    @media @screen-lt-s {
        flex-direction: column;
        gap: 8px;
    }
}
